import "./App.css";
import { useMemo } from "react";

import Home from "./Home";
import Toolbar from "@material-ui/core/Toolbar";
import { Button,  } from "@material-ui/core";
import Grid from '@material-ui/core/Grid'
import bg from './bg.jpg';
import eyes from './eyes.svg';
import grail from './grail.png';

import gith from "./gith.png";
import twitter from "./twitter.png";
import disc from "./disc.png";
import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from "@solana/wallet-adapter-wallets";

import gify from "./1.gif";


import r1 from "./r1.png";
import r2 from "./r2.png";
import r3 from "./r3.png";
import r4 from "./r4.png";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { createTheme, ThemeProvider } from "@material-ui/core";

const treasury = new anchor.web3.PublicKey(
  process.env.REACT_APP_TREASURY_ADDRESS!
);

const config = new anchor.web3.PublicKey(
  process.env.REACT_APP_CANDY_MACHINE_CONFIG!
);

const candyMachineId = new anchor.web3.PublicKey(
  process.env.REACT_APP_CANDY_MACHINE_ID!
);

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const theme = createTheme({
    palette: {
        type: 'dark',
    },
    overrides: {
        MuiButtonBase: {
            root: {
                justifyContent: 'flex-start',
            },
        },
        MuiButton: {
            root: {
                textTransform: undefined,
                padding: '12px 16px',
            },
            startIcon: {
                marginRight: 8,
            },
            endIcon: {
                marginLeft: 8,
            },
        },
    },
});

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
        getPhantomWallet(),
        getSlopeWallet(),
        getSolflareWallet(),
        getSolletWallet({ network }),
        getSolletExtensionWallet({ network })
    ],
    []
  );

  return (  <Grid container >

{/* backgroundColor:'background: rgb(0,0,0)', background:'linear-gradient(128deg, rgba(255,255,255,1) 2%, rgba(75,240,255,1) 7%, rgba(0,123,255,1) 11%, rgba(31,77,255,1) 28%, rgba(255,93,93,1) 54%, rgba(53,149,255,1) 64%, rgba(33,193,199,1) 82%, rgba(25,211,175,1) 85%, rgba(251,0,0,1) 100%)' */}
    <Grid item xs={12}   style={{  backgroundImage: `url(${bg})`, backgroundPosition: 'center', 
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
}}> 
      <ThemeProvider theme={theme} >
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} autoConnect={true}>
            <WalletDialogProvider >         <Toolbar style={{background:'#000'  ,opacity:'0.9',  fontFamily:"roboto" ,fontSize:'80px' ,justifyContent:'right', alignItems:'right',height:'15vh'}}> 
           
         <div style={{width:'100vw' ,fontFamily:"roboto" ,display: 'flex',  justifyContent:'center', alignItems:'center', }}> Hood Nootz</div>
  
         {/* <Button ><a href="https://discord.gg" target="_blank" rel="noopener noreferrer"><img style={{height:'4vh'}}src={disc} alt="loading..." /></a></Button>  */}
         
           {/* <Button ><a href="https://twitter.com/" target="_blank" rel="noopener noreferrer"><img style={{height:'4.5vh'}}src={twitter} alt="loading..." /></a></Button>  */}
        
          
          
          </Toolbar>
           

<div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '2vh', paddingTop:'80px', paddingBottom:'0px',
              }}> 

              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: '10vw'}}></div>
              <h1 style={{fontFamily:"roboto" ,fontSize:'48px', color:'#000'}}>550 gang affiliated penguins</h1> 

              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: '10vw'}}></div>
              

              
              
             </div >  
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '60vh', paddingTop:'0px', paddingBottom:'0px',
              }}> 

              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: '10vw'}}></div>
              <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', width: '400px' ,height: '400px', backgroundColor:'##fff' , borderRadius: "10px", overflow:'hidden', padding:'8px'}}>
              
              <img style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: '100%' ,height: '100%',}}src={gify} alt="loading..." />
                </div> 
           

              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: '10vw'}}></div>
              

              
              
             </div >                 <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '2vh', paddingTop:'20px', paddingBottom:'48px',
              }}> 

              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: '10vw'}}></div>
              {/* <Home
              
              candyMachineId={candyMachineId}
              config={config}
              connection={connection}
              startDate={startDateSeed}
              treasury={treasury}
              txTimeout={txTimeout} 
              
            />   */}
<h1 style={{fontFamily:"roboto"}}  >SOLD OUT</h1> 
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: '10vw'}}></div>
              

              
              
             </div >          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '2vh', paddingTop:'20px', paddingBottom:'48px',
              }}> 

              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: '10vw'}}></div>
              {/* <Home
              
              candyMachineId={candyMachineId}
              config={config}
              connection={connection}
              startDate={startDateSeed}
              treasury={treasury}
              txTimeout={txTimeout} 
              
            />   */}
 <h1 style={{fontFamily:"roboto"}}  >JOIN OUR DISCORD    </h1> <Button ><a href="https://discord.gg/McCa3TMd9u" target="_blank" rel="noopener noreferrer"><img style={{height:'6vh' , paddingLeft:'12px'}}src={disc} alt="loading..." /></a></Button>
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: '10vw'}}></div>
              

              
              
             </div >  <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '2vh', paddingTop:'20px', paddingBottom:'48px',
              }}> 

              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: '10vw'}}></div>
              {/* <Home
              
              candyMachineId={candyMachineId}
              config={config}
              connection={connection}
              startDate={startDateSeed}
              treasury={treasury}
              txTimeout={txTimeout} 
              
            />   */} <h1 style={{fontFamily:"roboto"}}  >FOLLOW OUR TWITTER    </h1> 
          <Button ><a href="https://twitter.com/HoodNootGBS" target="_blank" rel="noopener noreferrer"><img style={{height:'4.5vh', paddingLeft:'12px'}}src={twitter} alt="loading..." /></a></Button>
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: '10vw'}}></div>
              

              
              
             </div >  




             <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '2vh', paddingTop:'20px', paddingBottom:'48px',
              }}> 

              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: '10vw'}}></div>
              {/* <Home
              
              candyMachineId={candyMachineId}
              config={config}
              connection={connection}
              startDate={startDateSeed}
              treasury={treasury}
              txTimeout={txTimeout} 
              
            />   */} <h1 style={{fontFamily:"roboto"}}  >BUY A HOOD NOOT   </h1> 
         
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: '10vw'}}></div>
              

              
              
             </div >  




             <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '2vh', paddingTop:'20px', paddingBottom:'48px',
              }}> 

              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: '10vw'}}></div>
              {/* <Home
              
              candyMachineId={candyMachineId}
              config={config}
              connection={connection}
              startDate={startDateSeed}
              treasury={treasury}
              txTimeout={txTimeout} 
              
            />   */}              <Button ><a href="https://digitaleyes.market/collections/Hood%20Noot%20Gang%20Business" target="_blank" rel="noopener noreferrer"><img style={{height:'4.5vh', paddingLeft:'12px'}}src={eyes} alt="loading..." /></a></Button>

         
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: '10vw'}}></div>
              

              
              
             </div >  

             {/* /////////////////// */}
{/* 
             //////////////////////// */}
             <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '2vh', paddingTop:'20px', paddingBottom:'48px',
              }}> 

              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: '10vw'}}></div>
              {/* <Home
              
              candyMachineId={candyMachineId}
              config={config}
              connection={connection}
              startDate={startDateSeed}
              treasury={treasury}
              txTimeout={txTimeout} 
              
            />   */}              <Button ><a href="https://grail.market/#/collection/hood-noot-gang-business" target="_blank" rel="noopener noreferrer"><img style={{height:'8.5vh', paddingLeft:'12px'}}src={grail} alt="loading..." /></a> </Button>

         
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: '10vw'}}></div>
              

              
              
             </div >  

             {/* /////////////////// */}
             

             
              {/* //CARD START*/}
   
             <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '2vh', paddingTop:'90px', paddingBottom:'48px',
              }}> 

          
              <h1 style={{fontFamily:"roboto" ,fontSize:'48px', }}>Powered by Metaplex Candy Machine, All Penguins are unique and WILL shoot.  </h1> 
   
            
              
              
             </div >        
     <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '2vh', paddingTop:'0px', paddingBottom:'48px',
              }}> 

              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: '10vw'}}></div>
   

              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: '10vw'}}></div>
              

              
              
             </div >    
            </WalletDialogProvider>
          </WalletProvider>
        </ConnectionProvider>
      </ThemeProvider>      </Grid>

      
    
   

    
</Grid>

  );
};

export default App;
